<template>
  <div>
    <b-card class="" style="">
      <b-table
        style="max-height: 60vh"
        :fields="tableColumns"
        :items="students"
        responsive
        sticky-header
        show-empty
        empty-text="No Data Available"
        :busy="isLoading"
        class="custom-table"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data"
          >{{ data.item.user_firstname }}
          {{ data.item.user_lastname }}
        </template>
        <template #cell(email)="data">
          <span @click="viewDetails(data.item.university_id)">
            {{ data.item.user_email }}</span
          >
        </template>
        <template #cell(phone)="data">
          {{ data.item.user_mobile }}
        </template>
      </b-table>
      <div class="loader" v-if="isLoading">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import CounselorServices from "@/apiServices/CounselorServices";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BTooltip,
  },

  data() {
    return {
      isLoading: false,
      students: [],
      tableColumns: [
        { key: "index", label: "Sr.No" },
        { key: "name", label: "Student Name", sortable: false },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        { key: "phone", label: "Phone No", sortable: false },
      ],
    };
  },
  methods: {
    async getStudents() {
      try {
        const university_id = this.$route.query.university_id || null;
        const res = await CounselorServices.recommendationByUniversity({
          university_id: university_id,
        });
        if (res.data.status) {
          this.students = res.data.data.data;
          console.log(this.students);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeMount() {
    this.getStudents();
  },
};
</script>
